import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue-apexcharts";
import router from "./router/index";
import store from "@/state/store";
import vco from "v-click-outside";
import "@/design/index.scss";
import Sparkline from "vue-sparklines";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import ContentEditable from "vue-contenteditable";
import VueRecord from "@codekraft-studio/vue-record";
import VueSocialSharing from "vue-social-sharing";
import VueMoment from "vue-moment";
import moment from "moment";
import "moment/locale/pt-br";
import { instance } from "@/api";

import { initFirebaseBackend } from "./authUtils";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId,
};

initFirebaseBackend(firebaseConfig);

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

Vue.use(VueSocialSharing);
Vue.use(VueRecord);
Vue.use(ContentEditable);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VueSweetalert2);
Vue.use(require("vue-chartist"));
Vue.use(vco);
Vue.use(Sparkline);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
  },
  installComponents: true,
});
Vue.use(VueMoment, { moment });

Vue.component("apexchart", VueApexCharts);

Vue.filter("image", getImageUrl);
Vue.filter("blob", getBlobUrl);
Vue.filter("date", dateFilter);
Vue.filter("name", nameFilter);
Vue.filter("time", timeFilter);
Vue.prototype.$getImageUrl = getImageUrl;
Vue.prototype.$defaultUserAvatar = require("@/assets/images/profile-default.jpg");
Vue.prototype.$defaultInterestImage = require("@/assets/images/interest-default.jpg");
Vue.prototype.$disqusShortCode = process.env.DISQUS;
Vue.prototype.$roleLabel = function roleLabel(role) {
  return (
    {
      admin: "Administrador",
      manager: "Moderador",
      publisher: "Uploader",
    }[role] || "Usuário"
  );
};

function getImageUrl(name) {
  if (!name) return "";
  return `${process.env.VUE_APP_IMAGES_URL}/${name}`;
}

function getBlobUrl(name) {
  if (!name) return "";
  return `${process.env.VUE_APP_BLOBS_URL}/${name}`;
}

function dateFilter(date) {
  return `${twoDigits(date.getDate())}/${twoDigits(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;
}

function timeFilter(date) {
  return `${twoDigits(date.getHours())}:${twoDigits(date.getMinutes())}`;
}

function twoDigits(n) {
  if (n < 10) {
    return `0${n}`;
  }

  return String(n);
}

function nameFilter(user) {
  if (user.name) {
    return [user.name, user.surname].join(" ");
  }

  return `@${user.username}`;
}

store.dispatch("auth/init");

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

setInterval(() => {
  const loggedIn = store.getters["auth/loggedIn"];
  if (loggedIn && store.dispatch) {
    store.dispatch("auth/checkCredentials").catch(() => {
      router.push({ name: "home" });
    });
  }
}, 10000);
