import { getFirebaseBackend } from "../../authUtils.js";
import * as api from "../../api";

export const state = {
  currentUser: loadState("auth.currentUser"),
  token: loadState("auth.token"),
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    saveState("auth.currentUser", newValue);
  },

  SET_TOKEN(state, newValue) {
    state.token = newValue;
    saveState("auth.token", newValue);
  },
};

export const getters = {
  loggedIn(state) {
    return !!state.token;
  },

  isGuest(state) {
    return !state.token;
  },
};

export const actions = {
  async init({ state, dispatch }) {
    api.setToken(state.token);
    dispatch("validate");
  },

  // Logs in the current user.
  logIn({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.token) return dispatch("validate");

    return api.login(email, password).then(({ token, user }) => {
      commit("SET_TOKEN", token);
      commit("SET_CURRENT_USER", user);
      return user;
    });
  },

  async logOut({ commit }) {
    api.setToken(null);
    commit("SET_CURRENT_USER", null);
    commit("SET_TOKEN", null);
  },

  register({ dispatch, getters }, user = {}) {
    if (getters.token) return dispatch("validate");

    return api.register(user).then(() => {
      return dispatch("logIn", {
        email: user.email,
        password: user.password,
      });
    });
  },

  resetPassword({ dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    return getFirebaseBackend()
      .forgetPassword(email)
      .then((response) => {
        const message = response.data;
        return message;
      });
  },

  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null);
    return api
      .me()
      .then((user) => {
        commit("SET_CURRENT_USER", user);
        return user;
      })
      .catch(() => {
        api.setToken(null);
        commit("SET_CURRENT_USER", null);
        commit("SET_TOKEN", null);
      });
  },

  async checkCredentials() {
    if (!state.currentUser) return Promise.resolve(null);
    return api.me();
  },
};

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

function loadState(key) {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (err) {
    return null;
  }
}
