export default [
  {
    path: "/",
    name: "home",
    component: () => import("./views/home"),
  },
  {
    path: "/pagina-inicial",
    meta: { authRequired: true },
    name: "dashboard",
    component: () => import("./views/dashboard"),
  },
  {
    path: "/editar-perfil",
    name: "editProfile",
    component: () => import("./views/editProfile"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/gerenciar-interesses",
    name: "listInterests",
    meta: { authRequired: true },
    component: () => import("./views/listInterests"),
  },
  {
    path: "/nova-senha",
    name: "newPassword",
    component: () => import("./views/new-password"),
  },
  {
    path: "/token",
    name: "token",
    component: () => import("./views/token-screen"),
  },
  {
    path: "/perfil/:username",
    name: "profile",
    component: () => import("./views/profile"),
  },
  {
    path: "/gerenciamento-conteudo",
    name: "manageQuestions",
    component: () => import("./views/manage-question"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/importar-planilha",
    name: "importSpreadsheet",
    component: () => import("./views/import-spreadsheet"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/gerenciamento-cashback",
    name: "manageCashback",
    component: () => import("./views/manage-cashback"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/gerenciamento-referral",
    name: "manageReferral",
    component: () => import("./views/manage-referral"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/criar-conteudo",
    name: "createQuestion",
    component: () => import("./views/create-question"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/editar-conteudo/:id",
    name: "editQuestion",
    component: () => import("./views/edit-question"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/questoes",
    name: "questions",
    component: () => import("./views/questions"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/informativos-de-jurisprudencia",
    name: "reports",
    component: () => import("./views/questions"),
    meta: {
      authRequired: true,
      isReport: true,
    },
  },
  {
    path: "/rankings",
    name: "rankings",
    component: () => import("./views/rankings"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/ver-questao/:id",
    name: "viewQuestion",
    component: () => import("./views/viewQuestion"),
    meta: {
      isQuestionPage: true,
      authRequired: true,
    },
  },
  {
    path: "/ver-notificacoes",
    name: "viewNotifications",
    component: () => import("./views/viewNotifications"),
    meta: { authRequired: true },
  },
  {
    path: "/professores",
    name: "selectMentor",
    component: () => import("./views/mentoring"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/usuarios-para-mentorar",
    name: "strayUsers",
    component: () => import("./views/stray-users"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/conversas",
    component: () => import("./views/conversationsContainer"),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "/",
        name: "conversations",
        component: () => import("@/components/Conversations"),
      },
      {
        path: "/nova-conversa",
        name: "createConversation",
        component: () => import("@/components/CreateConversation"),
      },
      {
        path: "/conversas/:id",
        name: "viewConversation",
        component: () => import("@/components/ViewConversation"),
      },
    ],
  },
  {
    path: "/meus-interesses",
    name: "myInterests",
    component: () => import("./views/my-interests"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/planos",
    name: "plan",
    component: () => import("./views/plan"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/cobranca/:code",
    name: "billing",
    component: () => import("./views/billing"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/home.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/termos",
    name: "terms",
    component: () => import("./views/terms"),
  },
];
