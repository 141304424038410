import axios from "axios";
import Vue from "vue";
import store from "@/state/store";
import router from "@/router";

const baseURL = process.env.VUE_APP_API_URL;

export const instance = axios.create({
  baseURL,
  headers: {
    Accepts: "application/json",
  },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },

  function handleInstanceError(error) {
    const status = error.response && error.response.status;
    const data = (error.response && error.response.data) || {};

    const loggedIn = store.getters["auth/loggedIn"];
    if (loggedIn && status === 401) {
      store.dispatch("auth/logOut");
      router.push({ name: "home" });
      return Promise.resolve();
    }

    if (status === 403 && data.requiresSubscription) {
      setTimeout(() => {
        Vue.prototype.$swal.close();
      }, 500);
      router.push({ name: "plan" });
      return Promise.resolve();
    }

    return Promise.reject(error);
  }
);

export function getBaseUrl() {
  return baseURL;
}

export function setToken(token) {
  instance.defaults.headers.common["Authorization"] = token
    ? `Bearer ${token}`
    : undefined;
  return instance;
}

export async function login(email, password) {
  const {
    data: { token },
  } = await instance.post("/login", { email, password });
  setToken(token);
  const user = await me();
  return { token, user };
}

export async function forgotPassword(email) {
  await instance.post("/forgot", { email });
}

export async function register(payload) {
  const {
    data: { user },
  } = await instance.post("/users", payload);
  return user;
}

export async function me() {
  const { data: user } = await instance.get("/me");
  return user;
}

export async function getHomePage() {
  const { data: homePage } = await instance.get("/homePage");
  return homePage;
}

export async function findMentors(customParams, ignorePagination = false) {
  const params = {
    ...customParams,
  };
  const res = await instance.get("/findMentors", { params });

  if (ignorePagination) {
    return res.data;
  }

  const pagination = res.data;

  return {
    total: +pagination.total,
    perPage: +pagination.perPage,
    page: +pagination.page,
    lastPage: +pagination.lastPage,
    items: pagination.data,
  };
}

export async function selectMentor(userId, questionId) {
  const {
    data: { conversation, message },
  } = await instance.post("/selectMentor", { userId, questionId });
  return { conversation, message };
}

export async function findUserWithoutMentors(
  customParams,
  ignorePagination = false
) {
  const params = {
    ...customParams,
  };
  const res = await instance.get("/findUserWithoutMentors", { params });

  if (ignorePagination) {
    return res.data;
  }

  const pagination = res.data;

  return {
    total: +pagination.total,
    perPage: +pagination.perPage,
    page: +pagination.page,
    lastPage: +pagination.lastPage,
    items: pagination.data,
  };
}

export async function getUsers(customParams, ignorePagination = false) {
  const params = {
    order: "id",
    direction: "asc",
    ...customParams,
  };
  const res = await instance.get("/users", { params });

  if (ignorePagination) {
    return res.data.data;
  }

  const pagination = res.data;

  return {
    total: +pagination.total,
    perPage: +pagination.perPage,
    page: +pagination.page,
    lastPage: +pagination.lastPage,
    items: pagination.data,
  };
}

export async function getUserById(id, customParams = {}) {
  const params = {
    ...customParams,
  };
  const { data: user } = await instance.get("/users/" + id, { params });
  return user;
}

export async function getUserStatsById(id) {
  const { data: stats } = await instance.get("/stats/users/" + id);
  return stats;
}

export async function getUserByUsername(username, customParams = {}) {
  const params = {
    ...customParams,
    username,
  };
  const {
    data: { data: users },
  } = await instance.get("/users", {
    params,
  });

  if (!users.length) {
    throw new Error("User not found");
  }

  return users[0];
}

export async function updateUser(id, payload) {
  const { data } = await instance.put(`/users/${id}`, payload);
  return data;
}

export async function getAllInterests() {
  const { data: interests } = await instance.get("/interests");
  return interests;
}

export async function getSession() {
  const { data: session } = await instance.get("/session");
  return session;
}

export async function getAllSubjects() {
  const { data: subjects } = await instance.get("/subjects");
  return subjects;
}

export async function getAllDisciplines() {
  const { data: subjects } = await instance.get("/disciplines");
  return subjects;
}

export async function getAllPlans() {
  const { data: subjects } = await instance.get("/payments");
  return subjects;
}

export async function getInterestsStats() {
  const { data: interestStats } = await instance.get("/interests/stats");
  return interestStats;
}

export async function getAllQuestionFormOptions(isReport) {
  const { data: options } = await instance.get("/questions/form-options", {params: { isReport }});
  return options;
}

export async function createInterest(name) {
  const {
    data: { interest },
  } = await instance.post("/interests", { name });
  return interest;
}

export async function doPayment(dataPayment) {
  const {
    data: { plan },
  } = await instance.post("/payments/pre-approvals", dataPayment);
  return plan;
}

export async function cancelPlan() {
  const {
    data: { plan },
  } = await instance.put("/payments/pre-approvals/cancel");
  return plan;
}

export async function updateInterest(id, data) {
  const {
    data: { interest },
  } = await instance.put(`/interests/${id}`, data);
  return interest;
}

export async function deleteInterest(id) {
  await instance.delete(`/interests/${id}`);
}

export async function getQuestions(customParams, ignorePagination = false) {
  const params = {
    order: "year",
    direction: "desc",
    isReport: false,
    ...customParams,
  };
  const res = await instance.get("/questions", { params });

  if (ignorePagination) {
    return res.data;
  }

  const pagination = res.data;

  return {
    total: +pagination.total,
    perPage: +pagination.perPage,
    page: +pagination.page,
    lastPage: +pagination.lastPage,
    items: pagination.data,
  };
}

export async function getCashbacks(customParams, ignorePagination = false) {
  const params = {
    order: "id",
    direction: "asc",
    ...customParams,
  };
  const res = await instance.get("/cashbacks", { params });

  if (ignorePagination) {
    return res.data;
  }

  const pagination = res.data;

  return {
    total: +pagination.total,
    perPage: +pagination.perPage,
    page: +pagination.page,
    lastPage: +pagination.lastPage,
    items: pagination.data,
  };
}

export async function updateCashback(id, data) {
  const {
    data: { question },
  } = await instance.put(`/cashbacks/${id}`, data);
  return question;
}

export async function getReferrals(customParams, ignorePagination = false) {
  const params = {
    order: "id",
    direction: "asc",
    ...customParams,
  };
  const res = await instance.get("/referrals", { params });

  if (ignorePagination) {
    return res.data;
  }

  const pagination = res.data;

  return {
    total: +pagination.total,
    perPage: +pagination.perPage,
    page: +pagination.page,
    lastPage: +pagination.lastPage,
    items: pagination.data,
  };
}

export async function updateReferral(id, data) {
  const {
    data: { question },
  } = await instance.put(`/referrals/${id}`, data);
  return question;
}

export async function getQuestionById(id, params = {}) {
  const { data: question } = await instance.get("/questions/" + id, { params });
  return question;
}

export async function getQuestionStatsById(id) {
  const { data: stats } = await instance.get("/stats/questions/" + id);
  return stats;
}

export async function updateQuestion(id, payload) {
  const { data } = await instance.put(`/questions/${id}`, payload);
  return data;
}

export async function createQuestion(payload) {
  const { data } = await instance.post("/questions", payload);
  return data;
}

export async function deleteQuestion(id) {
  await instance.delete(`/questions/${id}`);
}

export async function reportError(questionId, report) {
  await instance.post(`/questions/reportError/${questionId}`, { report });
}

export async function answerQuestion(questionId, answer) {
  const data = { questionId, answer };
  const { data: question } = await instance.post("/answer", data);
  return question;
}

export async function getQuestionComments(questionId) {
  const { data: comments } = await instance.get(
    `/questions/${questionId}/comments`
  );
  return comments;
}

export async function commentQuestion(questionId, content) {
  const {
    data: comment,
  } = await instance.post(`/questions/${questionId}/comments`, { content });
  return comment;
}

export async function updateComment(questionId, commentId, content) {
  const { data: comment } = await instance.put(
    `/questions/${questionId}/comments/${commentId}`,
    {
      content,
    }
  );
  return comment;
}

export async function deleteComment(questionId, commentId) {
  await instance.delete(`/questions/${questionId}/comments/${commentId}`);
}

export async function getQuestionReviews(questionId, customParams = {}) {
  const params = {
    ...customParams,
  };
  const {
    data: reviews,
  } = await instance.get(`/questions/${questionId}/reviews`, { params });
  return reviews;
}

export async function reviewQuestion(questionId, payload) {
  const { data: review } = await instance.post(
    `/questions/${questionId}/reviews`,
    payload
  );
  return review;
}

export async function updateReview(
  questionId,
  reviewId,
  payload,
  customParams = {}
) {
  const params = {
    ...customParams,
  };
  const {
    data: review,
  } = await instance.put(
    `/questions/${questionId}/reviews/${reviewId}`,
    payload,
    { params }
  );
  return review;
}

export async function reactToReview(
  questionId,
  reviewId,
  isLike,
  customParams = {}
) {
  const params = {
    ...customParams,
  };
  const { data: review } = await instance.put(
    `/questions/${questionId}/reviews/${reviewId}/react`,
    { isLike },
    { params }
  );
  return review;
}

export async function switchReviewOrders(questionId, reviewAId, reviewBId) {
  const { data: updated } = await instance.post(
    `/questions/${questionId}/reviews/switchReviewOrders/${reviewAId}/${reviewBId}`
  );
  return updated;
}

export async function deleteReview(questionId, reviewId, customParams = {}) {
  const params = { ...customParams };
  await instance.delete(`/questions/${questionId}/reviews/${reviewId}`, {
    params,
  });
}

export async function getNotifications(customParams = {}) {
  const params = { ...customParams };
  const { data: notifications } = await instance.get(`/notifications`, {
    params,
  });
  return notifications;
}

export async function readNotification(id) {
  await instance.put(`/notifications/${id}/read`);
}

export async function uploadFile(file, type) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", type);
  const { data: blob } = await instance.post(
    `/uploadBlob?type=${type}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return blob;
}

export async function uploadSpreadsheet(file) {
  const formData = new FormData();
  formData.append("file", file);
  const { data: blob } = await instance.post(
    `/questions/spreadsheet`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return blob;
}

export async function getConversations(customParams, ignorePagination = false) {
  const params = {
    order: "id",
    direction: "asc",
    ...customParams,
  };
  const res = await instance.get("/conversations", { params });

  if (ignorePagination) {
    return res.data;
  }

  const pagination = res.data;

  return {
    total: +pagination.total,
    perPage: +pagination.perPage,
    page: +pagination.page,
    lastPage: +pagination.lastPage,
    items: pagination.data,
  };
}

export async function getConversationById(id, params = {}) {
  const { data: conversation } = await instance.get("/conversations/" + id, {
    params,
  });
  return conversation;
}

export async function getConversationMessages(
  id,
  params = {},
  ignorePagination = false
) {
  const res = await instance.get(`/conversations/${id}/messages`, {
    params,
  });

  if (ignorePagination) {
    return res.data;
  }

  const pagination = res.data;

  return {
    total: +pagination.total,
    perPage: +pagination.perPage,
    page: +pagination.page,
    lastPage: +pagination.lastPage,
    items: pagination.data,
  };
}

export async function updateConversation(id, payload) {
  const { data } = await instance.put(`/conversations/${id}`, payload);
  return data;
}

export async function createConversation(payload) {
  const { data } = await instance.post("/conversations", payload);
  return data;
}

export async function replyToConversation(conversationId, { body }) {
  const { data } = await instance.post(
    `/conversations/${conversationId}/reply`,
    { body }
  );
  return data;
}

export async function deleteConversation(id) {
  await instance.delete(`/conversations/${id}`);
}

export async function deleteMessage(conversationId, messageId) {
  await instance.delete(
    `/conversations/${conversationId}/messages/${messageId}`
  );
}
